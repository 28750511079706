<template>
  <div class="activityDetails">
    <div class="info_box">
      <h3>{{ data.activityName || data.exhibitName }}</h3>
      <p v-if="data.isRegister">报名日期：{{ data.registrationTime }}</p>
      <p>{{ typeText }}时间：{{ data.activityDate }} {{ data.activityTime }}</p>
      <p>
        {{ typeText }}地点：{{ data.activityAddress || data.exhibitAddress }}
      </p>
      <p>
        {{ typeText }}限制：{{ data.numberOnline
        }}{{ data.needParent === 1 ? '组' : '人' }}
        <span class="ageLimit"
          >年龄限制：{{ data.minAge + '-' + data.maxAge }}岁</span
        >
      </p>
      <p v-if="data.isRegister">
        报名限制：{{ data.numberRegister
        }}{{ data.needParent === 1 ? '组' : '人' }}，<span
          style="color: #0b6cf9"
          >{{ orderNum || 0 }}</span
        >{{ data.needParent === 1 ? '组' : '人' }}已报名
        <span class="lottery" v-if="data.needChoose">摇号</span>
      </p>
      <p v-if="data.needPay">
        {{ typeText }}费用：<span style="color: #fd4d4f"
          >￥{{ data.price }}元</span
        >
      </p>
      <div class="status" :style="{ backgroundColor: data.statusColor }">
        {{ data.statusText }}
      </div>
    </div>
    <div class="synopsis">
      <h3 class="title">{{ typeText }}简介</h3>
      <div
        class="html"
        v-html="data.activityIntroduction || data.exhibitIntroduction"
      ></div>
    </div>
    <van-button
      type="primary"
      class="btn_sty"
      @click="onSignUp"
      :disabled="disabled"
      v-if="data.isRegister"
      >{{ btnText }}</van-button
    >
    <MyNotice ref="myNotice" @agree="agree" />
  </div>
</template>
<script>
import { activity_find, exhibit_find } from '@/api/ActivityList';
import MyNotice from '@/components/MyNotice.vue';
export default {
  name: 'ActivityDetails',
  data() {
    return {
      type: '',
      id: null,
      typeText: '',
      options: [],
      findApi: null,
      data: {},
      orderNum: null,
      btnText: '',
      disabled: null,
    };
  },
  components: { MyNotice },
  computed: {},
  props: {
    clickRightNum: {
      type: Number,
      default: 0,
    },
    rightBtn: {
      type: String,
    },
  },
  created() {
    let { id, type } = this.$route.query;
    this.type = type;
    this.id = id;
    if (this.type === '活动详情') {
      this.typeText = '活动';
      this.findApi = activity_find;
      this.options = [
        { text: '报名未开始', value: 0, color: '#fd4d4f' },
        { text: '报名进行中', value: 1, color: '#4bd863' },
        { text: '活动未开始', value: 3, color: '#fd4d4f' },
        { text: '活动进行中', value: 4, color: '#ff9502' },
        { text: '活动已结束', value: 5, color: '#cccccc' },
        { text: '活动已取消', value: -1, color: '#cccccc' },
      ];
    } else if (this.type === '临展详情') {
      this.typeText = '临展';
      this.findApi = exhibit_find;
      this.options = [
        { text: '报名未开始', value: 0, color: '#fd4d4f' },
        { text: '报名进行中', value: 1, color: '#4bd863' },
        { text: '临展未开始', value: 3, color: '#fd4d4f' },
        { text: '临展进行中', value: 4, color: '#ff9502' },
        { text: '临展已结束', value: 5, color: '#cccccc' },
        { text: '临展已取消', value: -1, color: '#cccccc' },
      ];
    }
    this.getFind();
  },
  mounted() {},
  methods: {
    async getFind() {
      let form = {};
      if (this.typeText === '活动') {
        form.activityId = this.id;
      } else {
        form.exhibitId = this.id;
      }
      let {
        data: { activity, exhibit, orderNum, registerNumber },
      } = await this.findApi({ ...form });
      let {
        registrationStartTime,
        registrationEndTime,
        activityStartTime,
        activityEndTime,
        exhibitStartTime,
        exhibitEndTime,
        numberRegister,
        status,
        isRegister,
      } = activity || exhibit;

      if (orderNum >= numberRegister) {
        this.btnText = '报名已满';
        this.disabled = true;
      } else if (registerNumber) {
        this.btnText = '已报名（' + registerNumber + '）人';
        this.disabled = true;
      } else {
        this.btnText = '立即报名';
        if (status === 1) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      }
      let statusColor = '';
      let statusText = '';
      this.options.map((item) => {
        if (item.value === status) {
          statusColor = item.color;
          statusText = item.text;
        }
      });
      if (isRegister) {
        this.$emit('update:rightBtn', '报名须知');
      }
      this.data = { ...(activity || exhibit), statusColor, statusText };
      this.data.registrationTime =
        registrationStartTime + ' 至 ' + registrationEndTime;
      this.data.activityTime =
        (activityStartTime || exhibitStartTime) +
        ' 至 ' +
        (activityEndTime || exhibitEndTime);
      this.orderNum = orderNum;
    },
    onSignUp() {
      this.$refs.myNotice.init(this.typeText, true, this.data.notice);
    },
    agree() {
      this.$router.push({
        path: '/activityReservation',
        query: { id: this.id, type: this.type },
      });
    },
  },
  watch: {
    //点击预约须知
    clickRightNum() {
      this.$refs.myNotice.init(this.typeText, false, this.data.notice);
    },
  },
};
</script>
<style lang="scss" scoped>
.activityDetails {
  position: relative;
  .info_box {
    background-color: #fff;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 10px solid #ececec;
    h3 {
      font-size: 16px;
      color: #333;
      line-height: 30px;
      width: calc(100% - 70px);
    }
    p {
      font-size: 14px;
      color: #666;
      line-height: 25px;
    }
    .status {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
      color: #fff;
      height: 30px;
      line-height: 30px;
      text-align: right;
      padding: 0 5px 0 15px;
      border-radius: 0 0 0 30px;
    }
    .ageLimit {
      margin-left: 20px;
    }
    .lottery {
      margin-left: 15px;
      border: 1px solid #fd4d4f;
      padding: 2px 5px;
      color: #fd4d4f;
      font-size: 10px;
    }
  }
  .synopsis {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 30px;
    .title {
      font-size: 14px;
      color: #333;
      border-bottom: 2px solid #f5f5f5;
      line-height: 20px;
      padding-bottom: 5px;
    }
    ::v-deep .html {
      overflow: hidden;
      margin: 10px 0;
      font-size: 14px;
      img {
        max-width: 100%;
      }
      .ql-size-small {
        font-size: 10px;
      }
      .ql-size-large {
        font-size: 18px;
      }
      .ql-size-huge {
        font-size: 32px;
      }
      p {
        line-height: 1.8;
      }
    }
  }
  .btn_sty {
    height: 45px;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
    font-family: 'PingFangSC-Medium';
    text-align: center;
    line-height: 45px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}
</style>
